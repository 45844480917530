<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BCardCode,
    },
    directives: {
      Ripple,
    },
    mounted() {},
    data() {
      return {
        /* prettier-ignore */
        bancos: {
          "001": "Banco do Brasil S.A.",
          "003": "Banco da Amazônia S.A.",
          "004": "Banco do Nordeste do Brasil S.A.",
          "024": "Banco de Pernambuco S.A. - BANDEPE",
          "041": "Banco do Estado do Rio Grande do Sul S.A.",
          "045": "Banco Opportunity S.A.",
          "062": "Hipercard Banco Múltiplo S.A.",
          "070": "BRB - Banco de Brasília S.A.",
          "073": "BB Banco Popular do Brasil S.A.",
          "096": "Banco BM&F de Serviços de Liquidação e Custódia S.A",
          '104': "Caixa Econômica Federal",
          '148': "Multi Banco S.A.",
          '215': "Banco Comercial e de Investimento Sudameris S.A.",
          '222': "Banco Credit Agricole Brasil S.A.",
          '230': "Unicard Banco Múltiplo S.A.",
          '237': "Banco Bradesco S.A.",
          '249': "Banco Investcred Unibanco S.A.",
          '318': "Banco BMG S.A.",
          '341': "Itaú Unibanco S.A.",
          '376': "Banco J. P. Morgan S.A.",
          '394': "Banco Bradesco Financiamentos S.A.",
          '422': "Banco Safra S.A.",
          '473': "Banco Caixa Geral - Brasil S.A.",
          '479': "Banco ItaúBank S.A",
          '505': "Banco Credit Suisse (Brasil) S.A.",
          '623': "Banco Panamericano S.A.",
          '652': "Itaú Unibanco Holding S.A.",
          '655': "Banco Votorantim S.A.",
          '745': "Banco Citibank S.A.",
          '748': "Banco Cooperativo Sicredi S.A.",
          'M16': "Banco Rodobens S.A.",
          "021": "BANESTES S.A. Banco do Estado do Espírito Santo",
          "025": "Banco Alfa S.A.",
          "029": "Banco Banerj S.A.",
          "036": "Banco Bradesco BBI S.A.",
          "027": "Banco do Estado de Santa Catarina S.A.",
          "033": "Banco Santander (Brasil) S.A.",
          "037": "Banco do Estado do Pará S.A.",
          "044": "Banco BVA S.A.",
          "047": "Banco do Estado de Sergipe S.A.",
          "063": "Banco Ibi S.A. Banco Múltiplo",
          "072": "Banco Rural Mais S.A.",
          "074": "Banco J. Safra S.A.",
          '246': "Banco ABC Brasil S.A.",
          '255': "Banco BNY Mellon",
          '184': "Banco Itaú BBA S.A.",
          '214': "Banco Dibens S.A.",
          '218': "Banco Bonsucesso S.A.",
          '229': "Banco Cruzeiro do Sul S.A.",
          '356': "Banco ABN Amro Real S. A.",
          '389': "Banco Mercantil do Brasil S.A.",
          '399': "HSBC Bank Brasil S.A. - Banco Múltiplo",
          '409': "UNIBANCO - União de Bancos Brasileiros S.A.",
          '453': "Banco Rural S.A.",
          '477': "Citibank N.A.",
          '487': "Deutsche Bank S.A. - Banco",
          '612': "Banco Guanabara S.A.",
          '641': "Banco Alvorada S.A.",
          '653': "Banco Indusval S.A.",
          'M09': "Banco Itaucred Financiamentos S.A.",
          '197': "Stone Pagamentos",
          '117': "Advanced Cc Ltda",
          '735': "Neon Pagamentos",
          '340': "Superdigital",
          '290': "PagBank",
          '121': "Banco Agibank S.A.",
          '212': "Banco Original",
          '260': "Nubank",
          '172': "Albatross Ccv S.A",
          '323': "Mercado Pago",
          '188': "Ativa Investimentos S.A",
          '380': "PicPay",
          '280': "Avista S.A. Crédito, Financiamento e Investimento",
          '80': "B&T Cc Ltda",
          '654': "Banco A.J.Renner S.A.",
          '75': "Banco ABN AMRO S.A",
          '65': "Banco Andbank (Brasil) S.A.",
          '213': "Banco Arbi S.A.",
          '96': "Banco B3 S.A.",
          '752': "Banco BNP Paribas Brasil S.A.",
          '107': "Banco BOCOM BBM S.A.",
          '63': "Banco Bradescard S.A.",
          '31': "Código Banco Beg S.A.",
          '122': "Banco Bradesco BERJ S.A.",
          '204': "Banco Bradesco Cartões S.A.",
          '208': "Banco BTG Pactual S.A.",
          '336': "Banco C6 S.A - C6 Bank",
          '412': "Banco Capital S.A.",
          '40': "Banco Cargill S.A.",
          '368': "Banco Carrefour",
          '266': "Banco Cédula S.A.",
          '739': "Banco Cetelem S.A.",
          '233': "Banco Cifra S.A.",
          '241': "Banco Clássico S.A.",
          '756': "Banco Cooperativo do Brasil S.A. - BANCOOB",
          '69': "Banco Crefisa S.A.",
          '83': "Banco da China Brasil S.A.",
          '707': "Banco Daycoval S.A.",
          '51': "Banco de Desenvolvimento do Espírito Santo S.A.",
          '300': "Banco de La Nacion Argentina",
          '495': "Banco de La Provincia de Buenos Aires",
          '494': "Banco de La Republica Oriental del Uruguay",
          '335': "Banco Digio S.A",
          '196': "Banco Fair Corretora de Câmbio S.A",
          '265': "Banco Fator S.A.",
          '224': "Banco Fibra S.A.",
          '626': "Banco Ficsa S.A.",
          '94': "Banco Finaxis S.A.",
          '12': "Banco Inbursa S.A.",
          '604': "Banco Industrial do Brasil S.A.",
          '77': "Banco Inter S.A.",
          '29': "Banco Itaú Consignado S.A.",
          '217': "Banco John Deere S.A.",
          '76': "Banco KDB S.A.",
          '757': "Banco KEB HANA do Brasil S.A.",
          '600': "Banco Luso Brasileiro S.A.",
          '243': "Banco Máxima S.A.",
          '720': "Banco Maxinvest S.A.",
          '370': "Banco Mizuho do Brasil S.A.",
          '746': "Banco Modal S.A.",
          '66': "Banco Morgan Stanley S.A.",
          '456': "Banco MUFG Brasil S.A.",
          '7': "Banco Nacional de Desenvolvimento Econômico e Social - BNDES",
        },
        table: {
          items: [],
          fields: [
            {
              key: 'nomeDoTerceiroFavorecido',
              label: 'Nome Favorecido',
              sortable: true,
            },
            { key: 'codigoBancoDestino', label: 'Banco' },
            {
              key: 'codigoAgenciaDestino',
              label: 'Agência',
            },
            {
              key: 'contaCorrenteDestino',
              label: 'Conta Corrente',
            },
            {
              key: 'valorLancamento',
              label: 'Valor',
              formatter: this.utils().formatarValor,
            },
            { key: 'numeroDocumentoBanco', label: 'Documento' },
            { key: 'ocorrencias', label: 'Ocorrências', sortable: true },
          ],
          isBusy: false,
          sort: {
            by: '',
            desc: false,
            direction: 'asc',
          },
          styles: {
            status: [
              {
                GRPEPago: 'success',
                GRPEGerado: 'warning',
                GRPEExpirado: 'info',
              },
            ],
          },
        },
        pesquisar: {
          pagination: {
            currentPage: 1,
            total: 0,
            perPage: 10,
            options: [10, 25, 50, 100],
          },
        },
      }
    },
    methods: {
      files() {
        return {
          onInput: (event) => {},
          read: (cb) => {
            const file = this.$refs.file.files[0]
            if (!file) return this.$message.error('Informe o arquivo de importação')

            const reader = new FileReader()
            reader.onload = () => {
              cb(reader.result)
            }
            reader.readAsText(file)
          },
        }
      },
      tables() {
        return {
          preencher: async (arquivo) => {
            if (!arquivo) return this.$message.error('Informe o arquivo de importação')
            const response = await this.pagamentos().importar(arquivo)

            if (response) {
              var segmentosA = response.filter((res) => res.codigoSegmento === 'A')
              this.table.items = segmentosA
            } else this.table.items = []
          },
        }
      },
      pagamentos() {
        return {
          importar: async (arquivo) => {
            this.$refs.file.reset()
            return await useJwt.post(`pagamento/upload`, { retorno: arquivo }).then((response) => {
              if (response.data.status == 'erro') {
                console.error(response.data.mensagem)
                this.$message.error('Atenção! ' + response.data.mensagem)
                return
              }

              this.$message.success('Arquivo importado com sucesso')
              return response.data
            })
          },
        }
      },
      utils() {
        return {
          formatarValor: (number) => {
            const trimmedNumber = number.replace(/^0+/, '')
            const result = parseFloat(trimmedNumber) / 100
            const formattedResult = result.toFixed(2)
            return formattedResult
          },
        }
      },
    },
  }
</script>

<template>
  <div>
    <b-card-code title="Retorno" no-body>
      <b-card-body>
        <validation-observer ref="formImport">
          <b-form>
            <b-row>
              <b-col sm="3">
                <b-form-group label="Arquivo de Importação" label-for="competencia">
                  <validation-provider #default="{ errors }" name="Arquivo de importação" rules="required">
                    <b-form-file
                      class="mr-1"
                      ref="file"
                      accept=".txt"
                      placeholder="Escolha um arquivo"
                      @change="($event) => files().onInput($event)"
                      drop-placeholder="Deixe seu arquivo aqui..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 w-100 alinhamentoInputImportMargemBtn"
                  @click.prevent="files().read((arquivo) => tables().preencher(arquivo))"
                >
                  Importar Arquivo
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-table
          v-show="table.items.length > 0"
          id="retorno"
          striped
          responsive
          class="position-relative"
          :per-page="pesquisar.pagination.perPage"
          :items="table.items"
          :fields="table.fields"
          :sort-by.sync="table.sort.by"
          :sort-desc.sync="table.sort.desc"
          :sort-direction="table.sort.direction"
          :busy="table.isBusy"
          :current-page="pesquisar.pagination.currentPage"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #cell(codigoBancoDestino)="row">
            <b-badge pill variant="info">
              {{ bancos[row.item.codigoBancoDestino] }}
            </b-badge>
          </template>
          <template #cell(numeroDocumentoBanco)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.numeroDocumentoBanco) }}
            </div>
          </template>
          <template #cell(codigoAgenciaDestino)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.codigoAgenciaDestino) }}-<span style="color: black">{{ row.item.dvAgenciaDestino }}</span>
            </div>
          </template>
          <template #cell(contaCorrenteDestino)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.contaCorrenteDestino) }}-<span style="color: black">{{ row.item.dvContaDestino }}</span>
            </div>
          </template>
          <template #cell(ocorrencias)="row">
            <div style="display: flex; flex-direction: column">
              <div v-for="(ocorrencia, index) in row.item.ocorrencias" :key="index">
                <b-badge pill style="flex-grow: 0; margin: 10px" :variant="ocorrencia.codigo == '00' ? 'success' : 'danger'">
                  {{ ocorrencia.descricao }}
                </b-badge>
              </div>
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<style lang="scss" scoped></style>
